var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customerData
    ? _c(
        "div",
        [
          _c("MileProgress", {
            ref: "progress",
            attrs: { "customer-data": _vm.customerData },
            on: {
              "fetched-images": _vm.fetchedImages,
              "switch-staging": _vm.switchStaging,
            },
          }),
          _c(
            "transition",
            { attrs: { name: "mile-staging-fade" } },
            [
              _vm.isShowStaging
                ? _c("MileStaging", {
                    attrs: { images: _vm.stageImages },
                    on: { "switch-staging": _vm.switchStaging },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("MileOnlineStorePoint", {
            attrs: { "is-online-store-user": _vm.ecCustomerAlignment },
          }),
          _c("MileMypageOutline"),
          _c("MileBanner"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }