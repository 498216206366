<template>
  <div v-if="customerData">
    <MileProgress
      ref="progress"
      :customer-data="customerData"
      @fetched-images="fetchedImages"
      @switch-staging="switchStaging" />

    <transition name="mile-staging-fade">
      <MileStaging
        v-if="isShowStaging"
        :images="stageImages"
        @switch-staging="switchStaging" />
    </transition>

    <MileOnlineStorePoint :is-online-store-user="ecCustomerAlignment" />

    <MileMypageOutline />

    <MileBanner />
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { SystemDialogMessage } from '@/constants/enums';

export default defineComponent({
  components: {
    MileProgress: () => import('@/components/mypage/mile/Progress.vue'),
    MileStaging: () => import('@/components/mypage/mile/Staging.vue'),
    MileOnlineStorePoint: () =>
      import('@/components/mypage/mile/OnlineStorePoint.vue'),

    MileMypageOutline: () =>
      import('@/components/mypage/mile/MypageOutline.vue'),

    MileBanner: () => import('@/components/mypage/mile/Banner.vue')
  },

  setup: (props, context) => {
    const customerData = ref(undefined);
    const ecCustomerAlignment = ref(false);
    const isShowStaging = ref(false);
    const stageImages = ref(undefined);
    const scrollY = ref(0);
    const progress = ref(null);

    /**
     * 演出アニメーションの切り替え
     * @param {boolean} value
     */
    const switchStaging = (value) => {
      isShowStaging.value = value;
      if (value) {
        scrollY.value = window.pageYOffset;
      } else {
        window.scrollTo(0, 0);
        progress.value.restartCounter();
      }
    };

    /**
     * 取得した画像を格納
     * @param {object} images
     */
    const fetchedImages = (images) => {
      stageImages.value = images;
    };

    /**
     * ユーザの情報を取得
     */
    const fetchCustomerData = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('customer')
          .getCustomer();
        customerData.value = data;
        ecCustomerAlignment.value = customerData.value?.ec['is_alignment'] > 0;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    fetchCustomerData();

    return {
      customerData,
      ecCustomerAlignment,
      isShowStaging,
      progress,
      stageImages,
      switchStaging,
      fetchedImages
    };
  }
});
</script>

<style lang="scss" scoped>
.mile-staging-fade {
  &-enter-active,
  &-leave-active {
    transition: opacity ease 0.25s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
